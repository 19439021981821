import React, { useEffect } from "react"
import { Col, Collapse, Empty, message, Row, Spin } from "antd"
import { useIntl } from "gatsby-plugin-intl"

import withAuth from "../../components/withAuthHoc"
import Template from "../../layouts/base"
import CustomBreadcrumb from "../../components/commonBreadcrumb"
import authFetch from "../../services/network"
import useStateObject from "../../hooks/useStateObject"

const { Panel } = Collapse

const DataLake = () => {
  const intl = useIntl()
  const [state, setState] = useStateObject({
    data: null,
    showData: null,
  })

  const handleCrawlersTablesResponse = response => {
    if (response.status !== 200) {
      message.error(
        intl.formatMessage({
          id: "page-datalake-consume-get-crawlers-tables-ko",
        })
      )
    } else {
      response.json().then(data => {
        setState({
          data,
          showData: data.map(d => buildShowData(d)),
        })
      })
    }
  }

  const buildShowData = data => {
    if (!data) {
      return
    }

    const { Name, DatabaseName, StorageDescriptor } = data
    const columns = StorageDescriptor && StorageDescriptor.Columns
    return {
      name: Name,
      databaseName: DatabaseName,
      columns: columns,
    }
  }

  useEffect(() => {
    authFetch(
      `${process.env.GATSBY_CONF_API_URL}/crawlers/tables/`
    ).then(response => handleCrawlersTablesResponse(response))
  }, [])

  const renderDataLakeTables = () => {
    return (
      <Row type="flex" justify="start" style={{ padding: "30px" }}>
        <Col>
          <Collapse accordion>
            {state.showData.map((sd, index) => (
              <Panel
                header={
                  <label style={{ fontSize: "11px" }}>
                    <span>
                      {intl.formatMessage({
                        id: "page-datalake-consume-table-title",
                      })}
                      :
                      <span style={{ fontWeight: "bold", fontSize: "14px" }}>
                        {sd.name}
                      </span>
                    </span>
                    <span style={{ margin: "0 10px" }}> - </span>
                    <span>
                      {intl.formatMessage({
                        id: "page-datalake-consume-db-title",
                      })}
                      :
                      <span style={{ fontWeight: "bold", fontSize: "14px" }}>
                        {sd.databaseName}
                      </span>
                    </span>
                  </label>
                }
                key={index}
              >
                <ul style={{ padding: "0 1.5rem" }}>
                  {sd.columns.map(column => (
                    <li style={{ listStyle: "none" }}>
                      ·{" "}
                      <span style={{ fontWeight: "bold", fontSize: "13px" }}>
                        {column.Name}
                      </span>
                      <pre style={{ marginLeft: "5px", display: "inline" }}>
                        ({column.Type})
                      </pre>
                    </li>
                  ))}
                </ul>
              </Panel>
            ))}
          </Collapse>
        </Col>
      </Row>
    )
  }

  const renderNoData = () => {
    return (
      <Row type="flex" justify="center" style={{ padding: "30px" }}>
        <Col>
          <Empty />
        </Col>
      </Row>
    )
  }

  const renderLoading = () => {
    return (
      <Row type="flex" justify="center" style={{ padding: "30px" }}>
        <Col>
          <Spin size="large" />
        </Col>
      </Row>
    )
  }

  const { showData } = state

  return (
    <Template selected={["datalake", "datalake-catalog"]}>
      <CustomBreadcrumb
        crumbs={[
          intl.formatMessage({ id: "page-datalake-breadcrumb-datalake" }),
          intl.formatMessage({
            id: "page-datalake-breadcrumb-consumption",
          }),
        ]}
      />
      <Row>
        <Col className="intro">
          <section>
            <h1>
              {intl.formatMessage({
                id: "page-datalake-consume-title",
              })}
            </h1>
            <p>
              {intl.formatMessage({
                id: "page-datalake-consume-intro",
              })}
            </p>
          </section>
        </Col>
      </Row>
      {!showData
        ? renderLoading()
        : showData.length === 0
        ? renderNoData()
        : renderDataLakeTables()}
    </Template>
  )
}

export default withAuth(DataLake)
